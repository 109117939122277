import { DeviceStatus } from '@/@types';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

export type StatusBadgeTranslations =
  | 'error_detected'
  | 'not_in_use'
  | 'in_use'
  | 'in_repair';

interface StatusBadgeProps {
  status: DeviceStatus;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'flex max-w-max items-center rounded-4 border-1 p-4 text-center text-12_16.8 tracking-0.24 text-gray-10 sm:p-8 md:h-32 xl:px-12',
        status === DeviceStatus.ERROR_DETECTED &&
          'border-statusErrorBorder bg-statusErrorBg',
        status === DeviceStatus.NOT_IN_USE &&
          'border-statusNotInUseBorder bg-statusNotInUseBg',
        status === DeviceStatus.IN_USE &&
          'border-statusInUseBorder bg-statusInUseBg',
        status === DeviceStatus.IN_REPAIR &&
          'border-statusInRepairBorder bg-statusInRepairBg'
      )}
    >
      <span className="max-w-134 text-wrap text-10_16.8 sm:text-12_16.8 xl:text-14_21">
        {t(
          `shared.deviceStatus.${status.toLowerCase() as StatusBadgeTranslations}`
        )}
      </span>
    </div>
  );
};
